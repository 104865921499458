<template>
  <el-alert class="m-t-2 m-b-2" show-icon title :closable="false">
    <span v-html="content"></span>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </el-alert>
</template>

<script>
import { get } from 'lodash';
import DebugItem from '@/components/DebugItem';

export default {
  name: 'ANZHINT',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  components: {
    DebugItem
  },

  computed: {
    debug() {
      return this.$store.state.app.debug;
    }
  },

  data() {
    return {
      content: null
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.data.InfoTooltipNumber) {
        this.requestToolTip(this.data);
      }
    },

    requestToolTip(node) {
      this.$store.dispatch('GET_TOOLTIP', node).then(result => {
        const RESPONSE = get(result, 'data.RESPONSE', '');
        this.content = RESPONSE;
      });
    }
  },

  watch: {
    data() {
      this.init();
    }
  }
};
</script>
